/** @format */

import React, { useState } from "react";
import Layout from "../components/Layout";
import { Row, Col, Form, Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { passwordValidator, emailValidator } from "../utils/validaciones";
import { Link } from "gatsby";

export default function Registro() {
  const [validacion, setValidacion] = useState("");

  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [errores, setErrores] = useState({
    nombre: "",
    apellido: "",
    email: "",
    password: "",
    aceptaTerminos: "",
  });

  const [registro, setRegistro] = useState({
    nombre: "",
    apellido: "",
    email: "",
    password: "",
    aceptaTerminos: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    let valido = true;
    // se limpian los errores.
    let validacionErrores = { nombre: "", apellido: "", email: "", password: "", aceptaTerminos: "" };

    // se revisa que se han validado las propiedades.
    if (registro.nombre.length === 0) {
      validacionErrores.nombre = "El campo nombre es requerido";
      valido = false;
    }
    if (registro.apellido.length === 0) {
      validacionErrores.apellido = "El campo apellido es requerido";
      valido = false;
    }
    if (registro.email.length === 0) {
      validacionErrores.email = "El campo de correo es requerido";
      valido = false;
    }
    if (registro.password.length === 0) {
      validacionErrores.password = "El de contraseña es requerido";
      valido = false;
    }
    if (registro.aceptaTerminos === false) {
      validacionErrores.aceptaTerminos = "Debes aceptar los términos y condiciones";
      valido = false;
    }
    if (!emailValidator.test(registro.email)) {
      validacionErrores.email = "Ingresa un correo valido. Ej: tucorreo@direccion.com";
      valido = false;
    }
    if (!passwordValidator.test(registro.password)) {
      validacionErrores.password =
        "El password debe contener al menos 8 caracteres con digito, letras mayuscula y minuscula.";
      valido = false;
    }
    setErrores(validacionErrores);

    if (valido) {
      fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/user/register`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          nombre: registro.nombre,
          apellido: registro.apellido,
          email: registro.email,
          password: registro.password,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            setRegistroExitoso(true);
          } else {
            setValidacion(await response.text());
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "email")
      if (!emailValidator.test(event.target.value))
        setErrores({ ...errores, email: "Ingresa un correo valido. Ej: tucorreo@direccion.com" });
      else setErrores({ ...errores, email: "" });

    if (event.target.name === "password")
      if (!passwordValidator.test(event.target.value))
        setErrores({ ...errores, password: "Ingresa al menos 8 caracteres con digito, letras mayuscula y minuscula." });
      else setErrores({ ...errores, password: "" });

    if (event.target.name === "aceptaTerminos") setRegistro({ ...registro, [event.target.name]: event.target.checked });
    else setRegistro({ ...registro, [event.target.name]: event.target.value });
  };

  if (registroExitoso) {
    return (
      <Layout>
        <div className="registro-exitoso">
          <h1>
            Tu registro ha sido exitoso!! Te hemos enviado un correo electrónico para que puedas validar tu registro.
            <br /> Esperamos que tu experiencia sea placentera.{" "}
          </h1>
        </div>
      </Layout>
    );
  } else
    return (
      <Layout>
        <Row className="mt-5 me-lg-5 mb-lg-5 shadow" style={{ backgroundColor: "#90C0F0" }}>
          <Col className="p-4 p-md-5" lg={7}>
            <div className="px-md-5 px-lg-0 px-xl-5">
              <StaticImage src="../images/ondas-agua.png" width={110} className="mb-3" alt="" />
              <h1 className="registro-subtitulo mb-4">Crea una cuenta de forma gratuita.</h1>
              <Form onSubmit={handleSubmit}>
                <span style={{ color: "red", fontWeight: "bold" }}>{validacion}</span>
                <Form.Group className="mb-3" controlId="txtNombre">
                  <Form.Control
                    type="text"
                    placeholder="Nombre"
                    name="nombre"
                    onChange={handleChange}
                    isInvalid={errores.nombre.length > 0}
                  />
                  <Form.Control.Feedback type="invalid">{errores.nombre}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="txtApellido">
                  <Form.Control
                    type="text"
                    placeholder="Apellido"
                    name="apellido"
                    onChange={handleChange}
                    isInvalid={errores.apellido.length > 0}
                  />
                  <Form.Control.Feedback type="invalid">{errores.apellido}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="txtEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    isInvalid={errores.email.length > 0}
                  />
                  <Form.Control.Feedback type="invalid">{errores.email}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="txtPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    isInvalid={errores.password.length > 0}
                  />
                  <Form.Control.Feedback type="invalid">{errores.password}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="chkPoliticas">
                  <Form.Check
                    type="checkbox"
                    label="Estoy de acuerdo con los Términos y Políticas de Privacidad."
                    name="aceptaTerminos"
                    onChange={handleChange}
                    isInvalid={errores.aceptaTerminos.length > 0}
                  />
                  <Form.Control.Feedback type="invalid">{errores.aceptaTerminos}</Form.Control.Feedback>
                </Form.Group>
                <Col>
                  <Button type="submit" className="btn-naranja mb-3 mb-md-0 ">
                    Registrarse
                  </Button>
                  <Link to="/login/" className="float-end">
                    <Button type="submit" className="btn-blanco">
                      Ingresar
                    </Button>
                  </Link>
                </Col>
              </Form>
            </div>
          </Col>
          <Col className="p-0" lg={5}>
            <StaticImage
              src="../images/tomando-foto-comida.png"
              alt=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Col>
        </Row>
      </Layout>
    );
}
